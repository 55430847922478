import { useOpenRunBlockModal, useUtils } from '@/ui/composables'
import { getCurrentInstance, onUnmounted } from 'vue'
import { useWorkflowEditor } from './useWorkflowEditor'

export function useEditorShortcut() {
  const { removeBlock, selectedBlock } = useWorkflowEditor()
  const { asyncContext } = useUtils()
  const { showRunBlockModal } = useOpenRunBlockModal()
  function enableGlobalShortcuts() {
    document.addEventListener('keydown', keydown)

    const hasInstance = getCurrentInstance()
    if (hasInstance) {
      onUnmounted(() => {
        document.removeEventListener('keydown', keydown)
      })
    }
  }

  function keydown(ev: KeyboardEvent) {
    const isInput = isInputElement(ev.target as HTMLElement)
    if (isInput) return

    // Selected block exists
    if (selectedBlock.value) {
      if (isDeleteKey(ev.key)) {
        asyncContext(() => removeBlock(selectedBlock.value!))
      }
      if (ev.key === 'r' && !ev.ctrlKey && !ev.shiftKey && !ev.metaKey) {
        showRunBlockModal()
      }
    }
  }

  function isInputElement(element: HTMLElement): boolean {
    if (element.isContentEditable) return true
    return element && ['INPUT', 'TEXTAREA'].includes(element.tagName)
  }

  function isDeleteKey(key: string): boolean {
    return ['Delete', 'Backspace'].includes(key)
  }

  return { enableGlobalShortcuts }
}
