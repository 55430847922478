<script setup lang="ts">
import type { BlockConfigDetailsFragment, RunItemFragment } from '@/generated/sdk'
import { useRunResults } from '@/runs'
import { CheckMark, DisplayDate, RunStatus, ShowMoreButton, TwinIcon } from '@/ui/components'
import { useUtils } from '@/ui/composables'
import type { BlockOutputDetails } from '@/workflow-edit/sidebar-right/block-sidebar'
import { Column, Row, Spinner } from '@madxnl/dodo-ui'
import { computed, watch } from 'vue'
import DataTableCell from './DataTableCell.vue'
import RunNotes from './RunNotes.vue'
import RunRating from './RunRating.vue'
import { useDataTable } from './useReviewDataTable'

const { selectedRunIds, toggleRun, selectAllRuns, resetSelectedRuns, updateRun } = useRunResults()
const { camelCaseToReadable } = useUtils()
const { tableRuns, allHeaders, rows, inputArguments: tableInputArgs } = useDataTable()

const props = defineProps<{
  blockConfig: BlockConfigDetailsFragment
  blockOutput: BlockOutputDetails
  inputArguments?: { name: string }[]
}>()

const runs = computed(() => props.blockOutput.runs.value)
const allRunsSelected = computed(() => selectedRunIds.value.length === runs.value?.length)

watch(props, () => (tableInputArgs.value = props.inputArguments), { immediate: true })
watch(runs, () => (tableRuns.value = runs.value), { immediate: true })

function clickSelectRun(runId: string) {
  toggleRun(runId)
}

function clickSelectAllRuns() {
  if (allRunsSelected.value) {
    resetSelectedRuns()
    return
  }
  const runIds = runs.value?.map((run) => run.id)
  if (!runIds) return
  selectAllRuns(runIds)
}

async function saveRating(rating: number | null, runId?: string) {
  if (!runId) return
  await updateRun({ id: runId, rating })
}

async function saveNotes(run: RunItemFragment, notes: string | null) {
  run.notes = notes // save optimistically
  await updateRun({ id: run.id, notes })
}
</script>

<template>
  <template v-if="runs">
    <Row v-if="runs.length === 0" :class="$style.colorDisabled" padding="s" justify="center">
      <TwinIcon icon="Info" size="s" />
      <small>No runs found</small>
    </Row>

    <div v-else>
      <div :class="$style.EditorDataTable">
        <table>
          <thead>
            <tr>
              <th v-for="header in allHeaders" :key="header" :class="[$style.colname]">
                <Row v-if="header === 'runId'">
                  <CheckMark
                    title="Select all runs"
                    :model-value="allRunsSelected"
                    @update:model-value="clickSelectAllRuns"
                  />
                </Row>

                <Row v-else>
                  <h4>{{ header }}</h4>
                </Row>
              </th>
            </tr>
          </thead>

          <tbody>
            <template v-for="(row, index) in rows" :key="row.id">
              <tr>
                <td v-for="(value, key) in row" :key="key" :class="$style.td">
                  <template v-if="key === 'runId' && typeof value === 'string'">
                    <CheckMark
                      :title="value"
                      :model-value="selectedRunIds.includes(value)"
                      @update:model-value="clickSelectRun(value)"
                    />
                  </template>

                  <template v-else-if="key === 'Run' && typeof value === 'string'">
                    <Column gap="0">
                      Run
                      <small :class="$style.colorDisabled">
                        <DisplayDate :date="parseInt(value)" />
                      </small>
                    </Column>
                  </template>

                  <template v-else-if="key === 'Status' && typeof value === 'string'">
                    <div style="width: 96px">
                      <RunStatus :run="runs[index]" />
                    </div>
                  </template>

                  <template v-else-if="key === 'Rating'">
                    <Row>
                      <RunRating :run-id="runs[index]!.id" :rating="runs[index]!.rating" @update-rating="saveRating" />
                      <RunNotes :run="runs[index]!" @update-notes="saveNotes" />
                    </Row>
                  </template>

                  <template v-else>
                    <DataTableCell :value="value" :name="key" :modal-title="camelCaseToReadable(key)" />
                  </template>
                </td>

                <td :class="$style.filler" />
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <Row v-if="blockOutput.showMore.value" justify="center">
        <ShowMoreButton :show-more="blockOutput.showMore.value" variant="clear" />
      </Row>
    </div>
  </template>

  <Spinner v-else />
</template>

<style module>
.EditorDataTable {
  position: relative;
  overflow-x: auto;
  padding-bottom: 8px;
  border-bottom: 2px solid var(--grey-2-lines);
}

.EditorDataTable table {
  width: 100%;
  white-space: nowrap;
}

.EditorDataTable thead {
  border-bottom: 2px solid var(--grey-2-lines);
}

.EditorDataTable tbody tr:first-of-type td {
  padding-top: 16px;
}

.EditorDataTable th {
  padding: 8px 16px 8px 8px;
}

.td {
  padding: 8px;
}

.filler {
  padding: 0;
  width: 100%;
}

.colname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 8px;
}

.pendingRun {
  height: 90px;
  max-width: 100%;
}

.pendingRun div {
  position: absolute;
  display: flex;
  left: 0px;
  right: 0px;
  z-index: 31;
  text-align: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 90px;
  border-top: 2px solid #eee;
}

.listTitle,
.listOption {
  border-bottom: 1px solid var(--grey-2-lines);
  padding: 8px 0;
}

.listOptionEmpty {
  padding: 8px 0;
}

.colorDisabled {
  color: var(--grey-4-disabled);
}
</style>
