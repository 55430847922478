<script setup lang="ts">
import { WorkflowBlockType, type WorkflowBlockItemFragment } from '@/generated/sdk'
import { TwinIcon } from '@/ui/components'
import { useOpenRunBlockModal, useUtils } from '@/ui/composables'
import { useEditorLinks, useEditorState, useWorkflowDetails, useWorkflowEditor } from '@/workflow-edit'
import { RunBlockConfigModal } from '@/workflows/runblockconfig'
import { Button, Column, FormItem, Icon, Row, Tabs, TextInput, useValidation } from '@madxnl/dodo-ui'
import { computed, onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { BlockOutput, BlockSettings, LoopBlockSettings, SelectSubworkflow } from './'

const props = defineProps<{
  block: WorkflowBlockItemFragment
}>()

const router = useRouter()
const { linkReviewData } = useEditorLinks()
const { showRunBlockModal } = useOpenRunBlockModal()
const { getState, setState } = useEditorState()
const { workflow, updateBlock } = useWorkflowDetails()
const { removeBlock, getNextBlocks, addNewBlock, getBlockOutputConditions, checkNameExists } = useWorkflowEditor()
const { debounce } = useUtils()

const blockSettingsTabs = [
  { key: 'general' as const, name: 'General', slot: 'general' },
  { key: 'input' as const, name: 'Input', slot: 'input' },
  { key: 'output' as const, name: 'Output', slot: 'output' },
]

const newCondition = ref('')
const newTitle = ref(props.block.name ?? '')

const tabIndex = computed({
  get: () => {
    const currentTab = getState('blockSidebarTab')
    const idx = blockSettingsTabs.findIndex((tab) => tab.key === currentTab)
    return idx === -1 ? 0 : idx
  },
  set: (index) => {
    if (index === tabIndex.value) return
    const key = blockSettingsTabs[index]?.key ?? 'general'
    setState(key, 'blockSidebarTab')
  },
})

const { validate, errors } = useValidation({
  title: { value: newTitle, required: true, maxLen: 50, validators: [titleValidator] },
})

function titleValidator(title: string) {
  if (checkNameExists(title, props.block.id)) return 'The title must be unique.'
}

const nextBlocks = computed(() => getNextBlocks(props.block))
const allowDeleteBlock = computed(() => workflow.value?.draft && workflow.value.workflowBlocks.length !== 1)
const isWorkflowBlock = computed(() => props.block.blockConfig.block.includes('workflow') ?? false)
const isWorkflowLoopBlock = computed(() => loopConfig.value?.block.includes('workflow') ?? false)
const loopConfig = computed(() => props.block.blockConfig.loop?.blockConfig)

onUnmounted(() => {
  newCondition.value = ''
})

async function clickNewCondition() {
  const condition = newCondition.value
  await addNewBlock({ condition, previousBlocks: [{ id: props.block.id }] }, { select: false })
  newCondition.value = ''
}

const debouncedSaveTitle = debounce(async function saveBlockTitle() {
  const valid = await validate()
  if (!valid) return
  await updateBlock(props.block, { name: newTitle.value })
})

async function modifyCondition(oldCondition: string | null | undefined, newCondition: string | null) {
  const updatePromises = nextBlocks.value.map(async (b) => {
    if (b.condition === oldCondition) {
      await updateBlock(b, { condition: newCondition })
    }
  })
  await Promise.all(updatePromises)
}

async function clickDelete() {
  await removeBlock(props.block)
}

async function handleSubmitRunBlockConfig() {
  await router.push(linkReviewData(workflow.value!.id, props.block.id))
}
</script>

<template>
  <Tabs v-model:tab-index="tabIndex" :tabs="blockSettingsTabs" style="flex-grow: 1">
    <template #general>
      <FormItem label="Name" :error="errors.title">
        <TextInput
          v-model="newTitle"
          name="name"
          placeholder="Enter a name"
          :disabled="!workflow?.draft"
          @update:model-value="debouncedSaveTitle.trigger"
        />
      </FormItem>
      <FormItem v-if="block.blockType === WorkflowBlockType.Switch" label="Conditions">
        <template v-for="(condition, key) in getBlockOutputConditions(block)" :key="key">
          <TextInput
            :model-value="condition"
            :disabled="!workflow?.draft"
            @change="modifyCondition(condition, $event.target.value)"
          />
        </template>
        <Row>
          <TextInput v-model="newCondition" placeholder="Condition to add" />
          <Button @click="clickNewCondition">
            <Icon name="Add" size="s" />
            Add
          </Button>
        </Row>
      </FormItem>
      <LoopBlockSettings :workflow-block="block" />
      <Button color="primary" @click="showRunBlockModal">
        <TwinIcon icon="Play" />
        Run block
      </Button>

      <Column gap="l" style="margin-top: auto">
        <hr />
        <Button :disabled="!allowDeleteBlock" color="danger" @click="clickDelete">
          <TwinIcon icon="Delete" />
          Delete block
        </Button>
      </Column>
    </template>

    <template #input>
      <template v-if="workflow">
        <SelectSubworkflow v-if="isWorkflowBlock" :block-config="block.blockConfig" />
        <SelectSubworkflow v-if="loopConfig && isWorkflowLoopBlock" :block-config="loopConfig" />

        <BlockSettings :workflow="workflow" :config="loopConfig ?? block.blockConfig" context="settings" />
      </template>
    </template>

    <template #output>
      <BlockOutput v-if="workflow" :workflow-id="workflow.id" :workflow-block="block" />
    </template>
  </Tabs>

  <RunBlockConfigModal :block-config="loopConfig ?? block?.blockConfig" @submit="handleSubmitRunBlockConfig" />
</template>
