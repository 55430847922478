<script setup lang="ts">
import { Button, Icon } from '@madxnl/dodo-ui'
import { ref, watch } from 'vue'

const Rating = {
  Positive: 1,
  Negative: -1,
} as const

const props = defineProps<{
  runId?: string
  rating?: number | null
}>()

const rating = ref(props.rating)

watch(props, () => {
  rating.value = props.rating
})

const emit = defineEmits<{
  updateRating: [rating: number | null, runId?: string]
}>()

function clickRating(newRating: number | null) {
  if (newRating === rating.value) {
    rating.value = null
    emit('updateRating', null, props.runId)
    return
  }
  rating.value = newRating
  emit('updateRating', newRating, props.runId)
}
</script>

<template>
  <div :class="$style.rating">
    <Button
      :class="$style.ratingButton"
      size="s"
      variant="clear"
      :color="rating === Rating.Negative ? 'primary' : undefined"
      round
      @click="clickRating(Rating.Negative)"
    >
      <Icon name="thumb_down" fill />
    </Button>
    <Button
      :class="$style.ratingButton"
      size="s"
      variant="clear"
      :color="rating === Rating.Positive ? 'primary' : undefined"
      round
      @click="clickRating(Rating.Positive)"
    >
      <Icon name="thumb_up" fill />
    </Button>
  </div>
</template>

<style module>
.ratingButton {
  --button-color: var(--grey-3-outlines);
}
</style>
