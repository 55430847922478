import { MODE_DEV } from '@/environment'
import * as Sentry from '@sentry/vue'
import type { App } from 'vue'
import { version } from '../../package.json'

const dsn = 'https://1053f5e66f0c1dbc2fda9237b498f186@o4506518735683584.ingest.sentry.io/4506518743678976'

export function sentryPlugin(app: App) {
  if (!MODE_DEV) {
    Sentry.init({
      app,
      dsn,
      release: version,
      environment: window.location.hostname,
      integrations: [
        // Performance tracing
        Sentry.browserTracingIntegration({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        }),
        // Session Replay
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }
}
