<script setup lang="ts">
import type { WorkflowInputArgumentFragment } from '@/generated/sdk'
import { CheckMark, LargeModalLayout, TwinIcon } from '@/ui/components'
import { useSimpleMessage } from '@/ui/composables'
import { Button, Column, Form, FormItem, Row, Select, TextInput } from '@madxnl/dodo-ui'
import { computed, ref, watchEffect } from 'vue'
import { useFieldTypeOptions, useWorkflowSettingForm, useWorkflowSettings } from './composables'

const { deleteWorkflowArg } = useWorkflowSettings()
const { getInputFieldType, inputFieldOptions, setFieldType } = useFieldTypeOptions()
const { data, load, reset, errors, submit, selectedType } = useWorkflowSettingForm()
const { showMessage } = useSimpleMessage()

const props = defineProps<{
  setting: WorkflowInputArgumentFragment
  disabled: boolean
}>()

const isEditing = ref(false)

const type = computed(() => getInputFieldType(props.setting))

watchEffect(() => (isEditing.value ? load(props.setting) : reset()))

async function clickSubmit() {
  const submitted = await submit()
  if (!submitted) return
  isEditing.value = false
  showMessage('Input data field updated')
}
</script>

<template>
  <Column gap="s">
    <Row justify="between">
      <h5>{{ setting.name }}</h5>
      <Row>
        <Button color="secondary" square size="s" variant="link" :disabled="disabled" @click="isEditing = true">
          <TwinIcon size="s" icon="Edit" />
        </Button>
        <Button
          color="secondary"
          square
          size="s"
          variant="link"
          :disabled="disabled"
          @click="deleteWorkflowArg(setting)"
        >
          <TwinIcon size="s" icon="Delete" />
        </Button>
      </Row>
    </Row>

    <FormItem :description="setting.description">
      <Select
        :model-value="type"
        :options="inputFieldOptions.map((x) => x)"
        :disabled="disabled"
        @update:model-value="(type) => setFieldType(setting, type)"
      />
    </FormItem>
  </Column>

  <LargeModalLayout :open="isEditing" title="Edit input field" @close="isEditing = false">
    <template #content>
      <Form @submit="clickSubmit">
        <FormItem label="Name" :error="errors.name">
          <TextInput v-model="data.name" type="text" placeholder="Choose a name for this input" />
        </FormItem>
        <FormItem label="Description" :error="errors.description">
          <TextInput v-model="data.description" type="text" placeholder="Describe this input" />
        </FormItem>
        <FormItem label="Input type" :error="errors.type">
          <Select v-model="data.type" :options="inputFieldOptions" />
        </FormItem>
        <FormItem v-if="selectedType && !selectedType.neverRequired" label="Is this field required?">
          <CheckMark v-model="data.required"> Required </CheckMark>
        </FormItem>
        <FormItem v-if="data.type === 'array'" label="List item type" :error="errors.arrayType">
          <Select v-model="data.itemType" :options="inputFieldOptions.filter((x) => x.argumentType !== 'Array')" />
        </FormItem>
      </Form>
    </template>
    <template #footer="{ close }">
      <Button @click="close()">Cancel</Button>
      <Button variant="solid" color="primary" type="submit" @click.prevent="clickSubmit">Save</Button>
    </template>
  </LargeModalLayout>
</template>
