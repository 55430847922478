<script setup lang="ts">
import { useWorkflowDetails, useWorkflowEditor } from '../composables'
import { BlockSidebar } from './block-sidebar'
import { WorkflowSidebar } from './workflow-sidebar'

const { workflow } = useWorkflowDetails()
const { selectedBlock } = useWorkflowEditor()
</script>

<template>
  <template v-if="workflow">
    <BlockSidebar v-if="selectedBlock" :key="selectedBlock.id" :block="selectedBlock" />
    <WorkflowSidebar v-else />
  </template>
</template>
