<script setup lang="ts">
import { BlockConfigArgumentType } from '@/generated/sdk'
import type { NavEntry } from '@/navigation/components'
import { MainPageLayout, Navbar } from '@/navigation/components'
import { TwinIcon } from '@/ui/components'
import { useOpenRunBlockModal } from '@/ui/composables'
import { EditorBlockCard, useEditorLinks, useWorkflowDetails, useWorkflowEditor } from '@/workflow-edit'
import { useBlockOutput } from '@/workflow-edit/sidebar-right/block-sidebar/'
import RunBlockConfigModal from '@/workflows/runblockconfig/RunBlockConfigModal.vue'
import { Button, Column, Dropdown, FormItem, Row, Select, TextInput } from '@madxnl/dodo-ui'
import { onBeforeUnmount, watch } from 'vue'
import { useRouter } from 'vue-router'
import DataActionBar from './DataActionBar.vue'
import DataPageTable from './DataPageTable.vue'
import RunRating from './RunRating.vue'
import { useWorkflowBlock } from './useWorkflowBlock'

const navEntries: NavEntry[] = [
  { position: 'start', isSeparator: true },
  { position: 'start', slot: 'back' },
  { position: 'end', slot: 'run' },
]

const router = useRouter()
const editorLinks = useEditorLinks()
const { workflow } = useWorkflowDetails()
const { selectedBlock, getBlock } = useWorkflowEditor()
const { workflowBlock, fetch: fetchWorkflowBlock } = useWorkflowBlock()
const { showRunBlockModal } = useOpenRunBlockModal()
const blockOutput = useBlockOutput()

const props = defineProps<{
  workflowId: string
  workflowBlockId: string
}>()

onBeforeUnmount(() => blockOutput.stopPolling())

watch(props, fetch, { immediate: true })

async function fetch() {
  await fetchWorkflowBlock(props.workflowBlockId)
  if (!workflowBlock.value) return
  await blockOutput.fetchOutput(workflowBlock.value.blockConfig.id)
}

async function handleReturn() {
  await router.push(editorLinks.linkBackToWorkflowEdit(props.workflowId))
}

async function handleEditBlock() {
  selectedBlock.value = getBlock(props.workflowBlockId)!
  await router.push(editorLinks.linkBackToWorkflowEdit(workflow.value!.id))
}
</script>

<template>
  <MainPageLayout full-screen>
    <template #navbar>
      <Navbar :nav-entries="navEntries">
        <template #back>
          <Button variant="link" color="primary" @click="handleReturn">
            <TwinIcon icon="ChevronLeft" />
            Edit workflow
          </Button>
        </template>

        <template #run>
          <Row gap="xs">
            <Button variant="solid" color="primary" @click="showRunBlockModal">
              <TwinIcon icon="Play" />
              Run block
            </Button>
          </Row>
        </template>
      </Navbar>
    </template>

    <Column gap="xl">
      <Column>
        <h1>Results of block: {{ workflowBlock?.name }}</h1>
        <Column>
          <p class="form-description">
            If the block's output data looks wrong, review the block settings and the input data. <br />
            Run the block again with modified input data to find out whether this improves the output.
          </p>
        </Column>
      </Column>

      <Row gap="xl" align="start">
        <Column gap="l" style="flex: 0 0 256px">
          <h3>Block</h3>

          <EditorBlockCard
            v-if="workflowBlock"
            :workflow-block="workflowBlock"
            :block-config="workflowBlock.blockConfig"
            :disabled="false"
            selected
            is-data-review
          />
        </Column>

        <Column gap="l" grow>
          <Row justify="between">
            <h3>Block input</h3>

            <Button variant="link" color="primary" style="margin-right: 0" @click="handleEditBlock">
              Edit block
            </Button>
          </Row>

          <template v-if="workflowBlock">
            <table :class="$style.table">
              <tr v-for="arg in workflowBlock.blockConfig.arguments" :key="arg.name">
                <th style="width: 256px">{{ arg.name }}</th>
                <td v-if="arg.argumentType === BlockConfigArgumentType.Reference">
                  <em>{{ arg.value }}</em>
                </td>
                <td v-else>{{ arg.value }}</td>
              </tr>
            </table>
          </template>
        </Column>
      </Row>

      <Column>
        <Row justify="between">
          <h3>Block output & input data</h3>
          <Row>
            <TextInput v-model="blockOutput.search.value" type="search" placeholder="Search" />

            <Dropdown>
              <template #trigger="{ toggle }">
                <Button variant="solid" color="primary" @click="toggle">
                  <TwinIcon icon="Filter" />
                  Filter
                </Button>
              </template>
              <template #content>
                <FormItem label="Filter by Status">
                  <Select v-model="blockOutput.filterStatus.value" :options="blockOutput.statusOptions" />
                </FormItem>

                <FormItem label="Filter by rating">
                  <RunRating
                    :rating="blockOutput.filterRating.value"
                    @update-rating="blockOutput.handleFilterRatingChange"
                  />
                </FormItem>
                <hr />
                <Button variant="clear" @click="blockOutput.resetFilters">Reset filters</Button>
              </template>
            </Dropdown>
          </Row>
        </Row>

        <DataPageTable
          v-if="workflowBlock"
          :block-config="workflowBlock.blockConfig"
          :block-output="blockOutput"
          :input-arguments="workflowBlock.blockConfig.arguments"
        />
      </Column>
    </Column>

    <template #footer>
      <DataActionBar
        :selected-run-ids="blockOutput.selectedRunIds.value"
        :is-workflow-draft="workflow?.draft ?? true"
        @rerun-selected-runs="() => blockOutput.handleRerunSelectedRuns(workflowId)"
        @download-selected-runs="blockOutput.downloadSelectedRuns"
        @delete-selected-runs="blockOutput.handleDeleteSelectedRuns"
      />
    </template>
  </MainPageLayout>

  <RunBlockConfigModal :block-config="workflowBlock?.blockConfig" @submit="fetch" />
</template>

<style module>
.table {
  border-bottom: 2px solid #eee;
}
.table th,
.table td {
  padding: 8px 0;
  border-top: 2px solid #eee;
}

.colname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
