<script lang="ts" setup>
import { BlockArgumentInputType, BlockArgumentType } from '@/generated/sdk'
import { Select, Textarea, TextInput } from '@madxnl/dodo-ui'
import { computed, ref, watchEffect } from 'vue'
import CheckMark from './CheckMark.vue'
import FileInput from './FileInput.vue'

const props = defineProps<{
  type?: {
    name?: string | null
    argumentType?: BlockArgumentType | null
    inputType?: BlockArgumentInputType | null
    options?: { label: string; value: unknown }[] | null
  } | null
  modelValue: unknown
  placeholder?: string
}>()

const emits = defineEmits<{
  'update:modelValue': [value: unknown]
}>()

const inputValue = ref<unknown>()

watchEffect(() => {
  inputValue.value = props.modelValue
})

watchEffect(() => {
  const value = inputValue.value
  const changed = value !== props.modelValue
  if (changed) emits('update:modelValue', value)
})

const availableOptions = computed(() => {
  if (props.type?.options && props.type?.inputType === BlockArgumentInputType.Select) {
    return props.type.options.map((option) => ({
      value: option.value as string,
      label: option.label,
    }))
  }
  return null
})

// This is a computed property that converts the inputValue to a string
const inputString = computed({
  get: () => (inputValue.value == null ? '' : String(inputValue.value)),
  set: (value: string) => {
    inputValue.value = value
  },
})

const inputBoolean = computed({
  get: () => Boolean(inputValue.value),
  set: (value: boolean) => {
    inputValue.value = value
  },
})
</script>

<template>
  <template v-if="type?.inputType === BlockArgumentInputType.Checkbox">
    <CheckMark v-model="inputBoolean">
      {{ type?.name }}
    </CheckMark>
  </template>

  <template v-else-if="type?.inputType === BlockArgumentInputType.Number">
    <TextInput
      :model-value="inputString"
      type="number"
      :placeholder="props.placeholder ?? 'Enter a number'"
      @update:model-value="inputValue = Number($event)"
    />
  </template>

  <template v-else-if="type?.inputType === BlockArgumentInputType.Text">
    <TextInput v-model="inputString" :placeholder="props.placeholder ?? 'Enter a value'" />
  </template>

  <template v-else-if="type?.inputType === BlockArgumentInputType.Select">
    <Select
      v-model="inputString"
      :options="availableOptions ?? []"
      :placeholder="props.placeholder ?? 'Select a value'"
    />
  </template>

  <template
    v-else-if="type?.argumentType === BlockArgumentType.Array && type?.inputType === BlockArgumentInputType.File"
  >
    <FileInput v-model="inputString" :multiple="true" :placeholder="props.placeholder ?? 'Choose files'" />
  </template>

  <template v-else-if="type?.inputType === BlockArgumentInputType.File">
    <FileInput v-model="inputString" :placeholder="props.placeholder ?? 'Choose a file'" />
  </template>

  <template v-else>
    <Textarea v-model="inputString" :placeholder="props.placeholder ?? 'Enter a value'" />
  </template>
</template>
