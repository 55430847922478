<script setup lang="ts">
import { DisplayDate, TwinIcon } from '@/ui/components'
import { Button, Card, Column, Grid, Row, Spinner } from '@madxnl/dodo-ui'
import { onMounted } from 'vue'
import { useIntegrations } from '../composables'

const { connections, deleteConnection, providers, connectProvider, init } = useIntegrations()

onMounted(async () => {
  await init()
})
</script>

<template>
  <div>
    <h3>Available integrations</h3>
    <Spinner v-if="providers === null" />
    <h4 v-else-if="providers.length === 0">No providers available</h4>
    <Grid v-else column-size="m">
      <Card v-for="provider in providers" :key="provider.provider">
        <h2>{{ provider.name }}</h2>
        <p>{{ provider.description }}</p>
        <Button color="primary" @click="connectProvider(provider)">Connect</Button>
      </Card>
    </Grid>
  </div>

  <div>
    <h3>Connected integrations</h3>
    <Spinner v-if="connections === null" />
    <h4 v-else-if="connections.length === 0">No connections available</h4>
    <Grid column-size="m">
      <Card v-for="connection in connections" :key="connection.id">
        <Row>
          <Column gap="s" grow>
            <h4>{{ connection.name || connection.provider }}</h4>
            <p>{{ connection.provider }}</p>
            <p class="form-description">Connected <DisplayDate :date="parseInt(connection.createdAt)" /></p>
          </Column>

          <Button
            color="danger"
            variant="link"
            square
            aria-label="Remove connection"
            @click="deleteConnection(connection)"
          >
            <TwinIcon icon="Delete" />
          </Button>
        </Row>
      </Card>
    </Grid>
  </div>
</template>
