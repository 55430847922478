import { useApiClient } from '@/api'
import type { DeleteRunMutation, RunInputSchema } from '@/generated/sdk'
import { ref } from 'vue'

const selectedRunIds = ref<string[]>([])

export function useRunResults() {
  const api = useApiClient()

  function toggleRun(runId: string) {
    if (selectedRunIds.value.includes(runId)) {
      const index = selectedRunIds.value.indexOf(runId)
      selectedRunIds.value.splice(index, 1)
      return
    }
    selectedRunIds.value.push(runId)
  }

  function selectAllRuns(runIds: string[]) {
    selectedRunIds.value = runIds
  }

  function resetSelectedRuns() {
    selectedRunIds.value = []
  }

  async function updateRun(input: RunInputSchema) {
    await api.client.updateRun({ input })
  }

  async function deleteSelectedRuns() {
    const promises: Promise<DeleteRunMutation>[] = selectedRunIds.value.map((runId) => api.client.deleteRun({ runId }))
    await Promise.all(promises)
    resetSelectedRuns()
  }

  return {
    selectedRunIds,
    toggleRun,
    selectAllRuns,
    resetSelectedRuns,
    deleteSelectedRuns,
    updateRun,
  }
}
