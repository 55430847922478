import type { BlockConfigDetailsFragment } from '@/generated/sdk'
import { useWorkflowDetails } from '@/workflow-edit'
import { useBlockOutput } from '@/workflow-edit/sidebar-right/block-sidebar'
import { computed, ref, watch } from 'vue'

export function useWorkflowData() {
  const { workflow } = useWorkflowDetails()
  const blockOutput = useBlockOutput()

  const selectedBlockConfigId = ref<string>()

  const blockConfigs = computed(() => workflow.value?.blockConfigs)
  const blockConfigSelectOptions = computed(() => [
    ...(blockConfigs.value?.map((blockConfig) => ({
      label: getDataName(blockConfig),
      value: blockConfig.id,
    })) ?? []),
  ])
  const initialBlockConfig = computed(() =>
    blockConfigs.value?.find(
      (blockConfig) =>
        blockConfig.referredLoop === null && blockConfig.loop === null && blockConfig.workflowBlock?.length === 0,
    ),
  )
  const selectedBlockConfig = computed(() =>
    blockConfigs.value?.find((blockConfig) => blockConfig.id === selectedBlockConfigId.value),
  )

  watch(workflow, () => (selectedBlockConfigId.value = initialBlockConfig.value?.id ?? ''), { immediate: true })
  watch(selectedBlockConfigId, fetch, { immediate: true })

  async function fetch() {
    if (!selectedBlockConfigId.value) return
    await blockOutput.fetchOutput(selectedBlockConfigId.value)
  }

  function getDataName(blockConfig: BlockConfigDetailsFragment | undefined) {
    if (!blockConfig) return 'Unknown'
    if (blockConfig === initialBlockConfig.value) return `${workflow.value?.name ?? 'Unknown'} (the workflow itself)`

    const workflowBlock =
      blockConfig.loop?.blockConfig?.workflowBlock?.[0] ??
      blockConfig.loop?.blockConfigs?.[0]?.workflowBlock?.[0] ??
      blockConfig.referredLoop?.blockConfig?.workflowBlock?.[0] ??
      blockConfig.referredLoop?.blockConfigs?.[0]?.workflowBlock?.[0] ??
      blockConfig.workflowBlock?.[0]

    if (!workflowBlock) return 'Unknown'
    return `${workflowBlock.workflow?.name || 'Unknown'} - ${workflowBlock.name}`
  }

  return {
    blockOutput,
    blockConfigs,
    selectedBlockConfigId,
    selectedBlockConfig,
    initialBlockConfig,
    blockConfigSelectOptions,
    getDataName,
  }
}
