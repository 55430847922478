import type {
  BlockArgumentDetailsFragment,
  BlockConfigArgumentFragment,
  BlockConfigFragment,
  WorkflowDetailsFragment,
} from '@/generated/sdk'
import { BlockArgumentType } from '@/generated/sdk'
import { useBlockTypes } from '@/workflow-edit/composables'
import { computed, type Ref } from 'vue'

export type SettingsField = {
  data: string | null
  asArray: unknown[] | null
  namePath: string[]
  blockTypeArg: BlockArgumentDetailsFragment | null
  argument: BlockConfigArgumentFragment | null
  properties?: SettingsField[]
  items?: SettingsField[]
}

export function useFieldGeneration(opts: {
  config: Ref<BlockConfigFragment | WorkflowDetailsFragment | null | undefined>
}) {
  const { getBlockType } = useBlockTypes()

  const blockType = computed(() => {
    if (!opts.config.value || 'blockConfigs' in opts.config.value) return null
    return getBlockType(opts.config.value)
  })
  const configArgs = computed(() => {
    if (!opts.config.value) return []
    if ('blockConfigs' in opts.config.value) return opts.config.value.result ?? [] // workflow output...
    return opts.config.value?.arguments || []
  })

  function generateFieldsRecursively(
    argument: BlockConfigArgumentFragment | null,
    data: string | null,
    namePath: string[],
    blockTypeArg: BlockArgumentDetailsFragment | null,
  ) {
    if (data == null && blockTypeArg?.defaultValue != null) {
      data = toStringOrNull(blockTypeArg?.defaultValue)
    }
    const asArray = parseArray(data)
    const field: SettingsField = { data, namePath, argument, blockTypeArg, asArray }
    if (blockTypeArg?.argumentType === BlockArgumentType.Array && blockTypeArg.items) {
      field.items = []
      for (let i = 0; asArray && i < asArray.length; i++) {
        const subData = toStringOrNull(asArray[i])
        const subPath = [...namePath, String(i)]
        field.items.push(generateFieldsRecursively(argument, subData, subPath, blockTypeArg.items))
      }
    } else if (blockTypeArg?.argumentType === BlockArgumentType.Object && blockTypeArg?.properties) {
      field.properties = []
      const dataObj = parseRecord(data)
      for (const property of blockTypeArg.properties) {
        if (!property.name) continue
        const subData = toStringOrNull(dataObj[property.name])
        const subPath = [...namePath, property.name]
        field.properties.push(generateFieldsRecursively(argument, subData, subPath, property))
      }
    }
    return field
  }

  function parseRecord(data: string | null): Record<string, unknown> {
    if (data == null) return {}
    try {
      const record = JSON.parse(data)
      if (typeof record === 'object' && !Array.isArray(record)) return record
    } catch (e) {
      return {}
    }
    return {}
  }

  function parseArray(data: string | null): unknown[] | null {
    if (data == null) return null
    try {
      const array = JSON.parse(data)
      if (Array.isArray(array)) return array
    } catch (e) {
      return null
    }
    return null
  }

  function toStringOrNull(value: unknown): string | null {
    if (value == null) return null
    if (typeof value === 'string') return value
    return JSON.stringify(value)
  }

  function generateFields() {
    const fields: SettingsField[] = []
    const blockTypeArgs = blockType.value?.arguments || []
    const argsSeen = new Set<string>()
    for (const blockTypeArg of blockTypeArgs) {
      if (!blockTypeArg.name) continue
      const argument = configArgs.value.find((a) => a.name === blockTypeArg.name) ?? null
      const argData = argument?.value ?? null
      fields.push(generateFieldsRecursively(argument, argData, [blockTypeArg.name], blockTypeArg))
      if (argument) argsSeen.add(argument.id)
    }
    for (const argument of configArgs.value) {
      // Add custom arguments that are not defined in the block type
      if (argsSeen.has(argument.id)) continue
      const argData = argument?.value ?? null
      fields.push(generateFieldsRecursively(argument, argData, [argument.name], null))
    }
    return fields
  }

  const generatedFields = computed(generateFields)

  const flatFields = computed(() => {
    const list: SettingsField[] = []
    const queue = [...generatedFields.value]
    while (queue.length > 0) {
      const field = queue.shift()!
      list.push(field)
      if (field.properties) queue.push(...field.properties)
      if (field.items) queue.push(...field.items)
    }
    return list
  })

  function getParentField(field: SettingsField) {
    const parentPath = field.namePath.slice(0, -1)
    return flatFields.value.find((f) => String(f.namePath) === String(parentPath))
  }

  return { generatedFields, getParentField }
}
