<script setup lang="ts">
import type { WorkflowBlockItemFragment } from '@/generated/sdk'
import { LargeModalLayout } from '@/ui/components'
import { useEditorLinks } from '@/workflow-edit'
import { useBlockOutput } from '@/workflow-edit/sidebar-right/block-sidebar'
import { Button, Card, Column, Spinner } from '@madxnl/dodo-ui'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const { linkReviewData } = useEditorLinks()
const { runs, fetchOutput } = useBlockOutput()

const props = defineProps<{
  workflowId: string
  workflowBlock: WorkflowBlockItemFragment
}>()

const showModal = ref(false)
const runOutputValue = ref<unknown>()

watch(() => props.workflowBlock.blockConfig.id, fetch, { immediate: true })

async function fetch() {
  await fetchOutput(props.workflowBlock.blockConfig.id)
}

async function clickReview() {
  await router.push(linkReviewData(props.workflowId, props.workflowBlock.id))
}

function openModal(value: unknown) {
  runOutputValue.value = value
  showModal.value = true
}
</script>

<template>
  <Button @click="clickReview">View block results</Button>

  <Column v-if="runs?.length" gap="m">
    <template v-for="run in runs" :key="run.id">
      <Card hoverable @click="openModal(run.output)">
        <small v-if="run.output == null" class="dodo-fade-secondary"><em>no output</em></small>
        <small v-else class="dodo-nowrap">{{ run.output }}</small>
      </Card>
    </template>
  </Column>

  <Column v-else-if="runs?.length === 0" justify="center" padding="m">
    <p class="form-description" style="text-align: center">No output items yet</p>
  </Column>

  <Spinner v-else />

  <LargeModalLayout :open="showModal" title="Output" size-xl @close="showModal = false">
    <template #content>
      <code>{{ runOutputValue }}</code>
    </template>
  </LargeModalLayout>
</template>
